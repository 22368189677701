@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@angular/material' as mat;
@import 'variables';

$t: mat.m2-define-typography-config($font-family: 'Titillium Web',
$headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.m2-define-typography-level(34px, 40px, 400, 'Titillium Web', normal),
$headline-5: mat.m2-define-typography-level(64px, 76.8px, 600, 'Titillium Web', normal),
$headline-6: mat.m2-define-typography-level(22px, 30px, 600, 'Titillium Web', normal),
$subtitle-1: mat.m2-define-typography-level(18px, 23.2px, 600, 'Titillium Web', normal),
$subtitle-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-1: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$caption: mat.m2-define-typography-level(12px, 18px, 400, 'Titillium Web', normal),
$button: mat.m2-define-typography-level(14px, 22px, 400, 'Titillium Web', normal));

@include spb.set-typography($t);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

@media screen and (min-width: $spb-max-width) {
  .spb-holder {
    margin: 0 auto;
    min-width: $spb-max-width;
    padding-left: 45px;
    width: 0;
  }
}

@media screen and (max-width: $spb-max-width) {
  .spb-holder {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: $spb-small) {
  .spb-holder {
    margin: 0 auto;
    padding: initial;
    width: 95%;
  }
}


/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}
